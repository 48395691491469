<template>
  <div class=container>
	<table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+3"><!--? print $base_title ?--></font></div></td>
          </tr>
		<tr><td><center><img width="800" src="https://cave.cs.columbia.edu/old/software/scene_collage/times_square_small.jpg"></center></td></tr>
          <tr>
            <td valign="baseline"><em>
              </em><div align="left">
                <p align="left">This page contains software and instructions to create and edit scene collages, as described in this paper:
              
</p><blockquote>
<b>"Scene Collages and Flexible Camera Arrays," </b><br>Y. Nomura, L. Zhang and S.K. Nayar, <br>Proceedings of Eurographics Symposium on Rendering, <br>Jun. 2007.<br> [<a href="http://www1.cs.columbia.edu/CAVE/publications/./pdfs/Nomura_EUROGRAPHICS07.pdf	" target="new">PDF</a>] [<a href="https://cave.cs.columbia.edu/reference?bid=216">bib</a>] [<a href="https://cave.cs.columbia.edu/copyright">©</a>]<br></blockquote>
			<p>You may refer to the <a href="https://cave.cs.columbia.edu/projects/categories/project?cid=Computational+Imaging&pid=Scene+Collages+and+Flexible+Camera+Arrays">project page</a> for more details. <b>Note that this is research software and may contain bugs or other issues--please use it at your own risk.</b>
			</p><p>If you experience major problems with it, you may <a href="mailto: webcave@lists.cs.columbia.edu">email us</a>, but please note that we <b>do not have the resources to deal with all issues</b>.</p>
			<hr width="100%">
            <p class="style3"><u>System Requirements</u></p>
			<p>The software requires Windows XP.</p>
            <p class="style3"><u>Downloading the software</u></p>
                <p>Please click <a href="https://cave.cs.columbia.edu/old/software/scene_collage/AutoSceneCollage.zip">here</a> to download the software as a zip file. After unzipping, you should see the following files:
				<!--? thumbedImage("unzip.png") ?-->
				</p><p> The two main applications are as follows:
				</p><ul>
					<li><b>AutoSceneCollage:</b> The main software that constructs scene collages from a set of images</li>
					<li><b>SceneCollageEditor:</b> An editor that allows one to manually edit collages and view them interactively</li>
				</ul>
			<p class="style3"><u>Tutorial</u></p>
			<p>Auto Scene Collage is easy to use. Simply run the AutoSceneCollage program and you will see the main window of Auto Scene Collage:
				<!--? thumbedImage("first.png") ?-->
				</p><p>Next we will use some of the image sets from our gallery to demonstrate how to use this software to create collages.
			</p><p class="style3"><u>Example 1: Atrium (Fully Automatic)</u></p>
				<p>To create a collage, you need to select a set of images and load them in to the software window. Click the <b>File</b> menu and then <b>Open</b> to select images as you typically do in other programs. (You can also directly drag-and-drop images from Explorer to the Auto Collage window.)
				<!--? thumbedImage("menu-open.png"); thumbedImage("box-open.png"); ?-->
				</p><p>Press Open and you will see your images loaded in the main window of Auto Collage as follows. The images are listed in the same order as they are selected.
				<!--? thumbedImage("reference.png") ?-->
				</p><p>The check mark indicates the reference image, which is the one that does not change its size and orientation. All other images will be aligned with respect to this reference image. By default, the first image is selected as the reference image; you can change it by clicking on any of the other image names.
				</p><p>Now from the <b>Build</b> menu, choose <b>Start</b>:
				<!--? thumbedImage("menu-start0.png") ?-->
				</p><p>This starts the automatic collage process, which can take between 2 and 10 minutes, depending on your computer's speed and memory. (On a laptop with a Core Duo 2GHz CPU and 2GB RAM, it takes about 4 minutes). During this period, the window is grayed out to let you know that it is computing:
				<!--? thumbedImage("ransac.png") ?-->
				</p><p>When it is done, the window will return to its normal color and every image will be marked OK. You will see the collage in a separate window:
				<!--? thumbedImage("ok.png"); thumbedImage("collage.png") ?-->
				</p><p>You can try the same process with other image sets from our gallery, such as the Book Store, Columbus Circle, South Street Seaport, and Time Square. 
			</p><p class="style3"><u>Example 2: Playroom (Changing Collage Parameters)</u></p>
				<p>If you apply the procedure from Example 1 to the Play Room image set, you will see the following warning box after you press <b>start</b>:
				<!--? thumbedImage("warning-size.png") ?-->
				</p><p>If you ignore it and press Continue, you will get a collage like this, in which some input images are missing:
				<!--? thumbedImage("playroom-default-contrast.png") ?-->
				</p><p>The missing images are indicated by "NG" in the collage window:
				<!--? thumbedImage("playroom-default-contrast-NG.png") ?-->
				</p><p>The reason for the missing photos is that the texture on the floor in the input images is weak, and not enough features are detected there. To fix this, you can change a parameter in the collage configuration. Click on the <b>Build</b> menu and then <b>Config</b>: 
				<!--? thumbedImage("menu-config.png") ?-->
				</p><p>You will see the following configuration window box:
				<!--? thumbedImage("box-config0.png") ?-->
				</p><p>Here, you can lower the <b>Contrast Threshold</b> (shown highlighted in the above image) from the default value of 0.1 to 0. Then click <b>OK</b>. This helps extract features on weakly textured image regions. Now, if you choose <b>Start</b> from the <b>Build</b> menu again, you will get a collage like this:
				<!--? thumbedImage("playroom-zero-contrast.png") ?-->
				</p><p>This time, all images are included in the collage, but now the boy is upside down. You can change the orientation of the collage by selecting a different image as the reference image. For example, if you select image 9 as the reference image (by checking it in the main window) and build the collage again, you will get a collage like this:
				<!--? thumbedImage("playroom-zero-contrast-ref9.png") ?-->
				</p><p>You can apply the same procedure (i.e., changing contrast threshold from the default 0.1 to 0) to the Church and Office datasets, and you will get collages that include all the images. For the Church example, you may want to use image 6 as the reference image.
			</p><p class="style3"><u>Example 3: Taxi (Collage Editing)</u></p>
				<p>For the taxi scene, if you apply the procedure from example 2 and select image 10 as reference image, you will get a collage like the following: 
				<!--? thumbedImage("taxi_before_editing2.png") ?-->
				</p><p>While this collage includes all input images, some targets of interest are hidden in an undesirable way, such as the body of the taxi. To fix this, we provide a scene collage editor tool that allows a user to change the image layer ordering. To use the tool, you must first save the collage results created by the auto collage software by clicking <b>File</b> =&gt; <b>Save Setup</b>.
				</p><p>Then run the <b>SceneCollageEditor.exe</b> program downloaded with AutoSceneCollage. Click on <b>File</b> =&gt; <b>Load</b> to load the setup file you just saved. What you see first is the collage editor window; all editing work is done here. Notice that each image lies on a different plane initially. You can use the following controls to change your view of the collage:
				</p><ul>
					<li>Left-click and drag to rotate it in 3D space.</li>
					<li>Right-click and drag to translate the entire collage.</li>
					<li>If you have a scroll wheel, you can zoom in or out by scrolling the wheel.</li>
					<li>If you click the <b>reset view</b> button, you will go back to the frontal view of the collage.
				</li></ul>
				<p>The first group of buttons below the <b>reset view</b> button allows you to change the appearance of the collage. You can toggle them to see their effects. For example, if you click <b>Colored Layers</b>, each image is highlighted in a different color. 
				</p><p>The second group of buttons below the <b>reset view</b> button is used to edit individual images in the collage. For example, if you click on <b>Translate</b>, you can use your mouse to move individual images. If you click on any of the <b>X</b>, <b>Y</b>, or <b>Z</b> buttons, you will only move along the selected direction. Similarly, you can click on <b>Rotate</b> and <b>Scale</b> buttons to adjust the orientation and size of each individual image. 
				</p><p>Getting back to our example, we can fix our hidden taxi by clicking <b>Translate</b> and choosing the <b>Z</b> translation mode. Then we select the image we need to move. The image will be highlighted in red as shown here:
				<!--? thumbedImage("editor_taxi_before_translatez.png") ?-->
				</p><p>Now we move the image to the front:
				<!--? thumbedImage("editor_taxi_after_translatez.png") ?-->
				</p><p>In the end, after going back to the front view (pressing the <b>reset view</b> button), you will see a collage like this:
				<!--? thumbedImage("editor_taxi_after_translatez_frontal.png") ?-->
			</p><p class="style3"><u>A Final Word About the Scene Collage Editor</u></p>
				<p>Please note that the scene collage editor can be used even without the setup file generated by the AutoSceneCollage program. In this case, after loading in all the images, you can move images around to create collages as you wish!
			</p></div></td></tr>
			</tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>